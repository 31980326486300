import styled from 'styled-components';

import { PermissionsProps } from './Permissions.types';

import { Container } from './Permissions.styles';
import { Switch } from '../../atoms/Switch';
import { Copy } from '../../atoms/Typography';
import { Avatar } from '../../atoms/Avatar';
import { Colors } from '../../../styles/colors';
import { PrimaryButton } from '../../atoms/Button';
import { media } from '../../../styles/media';

export const Permissions = ({ followers, onDeleteClick }: PermissionsProps) => {
  return (
    <Container>
      {followers?.map((follower) => (
        <Line key={follower.uuid}>
          <Follower>
            <FollowerLine1>
              <Avatar image={`${follower.avatar}`} />
            </FollowerLine1>
            <FollowerLine2>
              <Copy>{follower.name}</Copy>
              <Copy styleLevel={3}>{follower.email}</Copy>
            </FollowerLine2>
          </Follower>
          <Details>
            <Permission>
              <Copy styleLevel={4} isItalic={true}>
                View Board
              </Copy>
              <Switch isDisabled={true} isOn={true} color="warning" />
            </Permission>
            <Permission>
              <Copy styleLevel={4} isItalic={true}>
                Add Job
              </Copy>
              <Switch isDisabled={true} isOn={true} color="warning" />
            </Permission>
            <Permission>
              <Copy styleLevel={4} isItalic={true}>
                Edit Job
              </Copy>
              <Switch isDisabled={true} isOn={true} color="warning" />
            </Permission>
          </Details>
          <Action>
            <PrimaryButton size="tiny" onClick={() => onDeleteClick(follower)}>
              Revoke
            </PrimaryButton>
          </Action>
        </Line>
      ))}
    </Container>
  );
};

export const JobPermissions = ({ followers, onDeleteClick }: PermissionsProps) => {
  return (
    <Container>
      {followers?.map((follower) => (
        <Line key={follower.uuid}>
          <Follower>
            <FollowerLine1>
              <Avatar image={`${follower.avatar}`} />
            </FollowerLine1>
            <FollowerLine2>
              <Copy>{follower.name}</Copy>
              <Copy styleLevel={3}>{follower.email}</Copy>
            </FollowerLine2>
          </Follower>
          <Details>
            <Permission>
              <Copy styleLevel={4} isItalic={true}>
                View Board
              </Copy>
              <Switch isDisabled={true} isOn={true} color="warning" />
            </Permission>
            <Permission>
              <Copy styleLevel={4} isItalic={true}>
                View job
              </Copy>
              <Switch isDisabled={true} isOn={true} color="warning" />
            </Permission>
            <Permission>
              <Copy styleLevel={4} isItalic={true}>
                Edit job
              </Copy>
              <Switch isDisabled={true} isOn={true} color="warning" />
            </Permission>
          </Details>
          <Action>
            <PrimaryButton size="tiny" onClick={() => onDeleteClick(follower)}>
              Revoke
            </PrimaryButton>
          </Action>
        </Line>
      ))}
    </Container>
  );
};

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding-block: 4px 8px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.ContrastLightest};
  }

  ${media.from_mobile`
    gap: 16px;
    padding-block: 8px 16px;
  `}
`;

const Follower = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  gap: 8px;
  order: 1;
  ${media.from_mobile`
    order: 1;
  `}
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 3;
  order: 3;
  ${media.from_mobile`
    order: 2;
  `}
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 64px;
  order: 2;
  ${media.from_mobile`
    order: 3;
  `}
`;

const FollowerLine1 = styled.div`
  display: flex;
  align-items: center;
`;

const FollowerLine2 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Permission = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 64px;
`;
