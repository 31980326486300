import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateEventArgs, SortDirection } from '../../generated/types';
import { GET_EVENTS } from '../queries/getEvents';

export const CREATE_EVENT = gql`
  mutation CreateEvent($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: EventInputData!) {
    createEvent(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid
    }
  }
`;

export const useCreateEvent = (options?: MutationHookOptions<Pick<Mutation, 'createEvent'>, MutationCreateEventArgs>) =>
  useMutation<Pick<Mutation, 'createEvent'>, MutationCreateEventArgs>(CREATE_EVENT, {
    update(cache, mutationResult, options) {
      const { data: { createEvent = {} } = {} } = mutationResult;
      const { variables } = options;
      const { boardUuid } = variables;

      if (!createEvent.uuid) return;
      if (!boardUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Board',
          uuid: boardUuid,
        }),
        fields: {
          events(existingEvents = [], { toReference }) {
            return [...existingEvents, toReference(createEvent)];
          },
        },
      });
    },
    ...options,
  });
