import { Focus2 } from '@styled-icons/remix-line';
import { Focus2 as Focus2Fill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function TargetIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Focus2 />
    </Icon>
  );
}

export function TargetFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Focus2Fill />
    </Icon>
  );
}
