import { toast } from 'react-toastify';

import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { CopyIcon, ExternalLinkIcon, InfoIcon, LinkIcon, LinkSlashIcon } from '../../atoms/Icons';
import { Colors } from '../../../styles/colors';
import { SharedLinkProps } from './SharedLink.types';
import { Container, LinksContainer, InputContainer } from './SharedLink.styles';
import { Tooltip } from '../../atoms/Tooltip';
import { IconButton } from '../../atoms/Icons/Icon';
import { Copy } from '../../atoms/Typography';

export const SharedLink = ({ url, isPublic, onCopyActionClick, onOpenActionClick }: SharedLinkProps) => {
  const [_value, copy] = useCopyToClipboard();

  function handleCopyAction(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (!isPublic) {
      toast.success('Your Jobs Board is not public.');
      return;
    }
    try {
      copy(url);
      toast.success('Link copied to clipboard');
    } catch (error) {}
    if (onCopyActionClick) {
      onCopyActionClick(event);
    }
  }

  function handleOpenAction(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (!isPublic) {
      toast.success('Your Jobs Board is not public.');
      return;
    }
    try {
      window.open(url, '_blank');
    } catch (error) {}
    if (onOpenActionClick) {
      onOpenActionClick(event);
    }
  }

  if (!url) {
    return null;
  }

  return (
    <Container $isPublic={isPublic}>
      {isPublic ? <LinkIcon size={1.1} color={Colors.Primary} /> : <LinkSlashIcon size={1.1} color={Colors.Contrast} />}
      <InputContainer>
        <input type="text" value={url} onChange={() => {}} />
      </InputContainer>
      <LinksContainer>
        <Tooltip
          title={
            <IconButton onClick={handleCopyAction}>
              <CopyIcon size={0.8} color={isPublic ? Colors.Primary : Colors.Contrast} />
            </IconButton>
          }
        >
          <Copy styleLevel={2}>Copy URL</Copy>
        </Tooltip>
        <Tooltip
          title={
            <IconButton onClick={handleOpenAction}>
              <ExternalLinkIcon size={0.9} color={isPublic ? Colors.Primary : Colors.Contrast} />
            </IconButton>
          }
        >
          <Copy styleLevel={2}>Open in a new tab</Copy>
        </Tooltip>
      </LinksContainer>
    </Container>
  );
};
