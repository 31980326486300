import { Upload } from '@styled-icons/feather';
import { Icon, IconProps } from './Icon';

export function UploadIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Upload />
    </Icon>
  );
}
