import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div<{
  $isPublic?: boolean;
}>`
  border: 1px solid ${Colors.ContrastUltraLightest};
  display: flex;
  padding: 0 0 0 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  input {
    ${({ $isPublic }) => {
      if ($isPublic) {
        return css`
          color: ${Colors.PrimaryDarkest};
        `;
      }
      if (!$isPublic) {
        return css`
          color: ${Colors.ContrastLightest};
          cursor: not-allowed;
        `;
      }
    }};
  }

  ${({ $isPublic }) => {
    if ($isPublic) {
      return css`
        border: 1px solid ${Colors.ContrastLightest};
      `;
    }
  }};
`;

export const LinksContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  flex: 1;
  input {
    overflow: hidden;
    width: 100%;
    border: none;
    background: rgba(0, 0, 0, 0);
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
