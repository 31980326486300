import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateJobStatusArgs } from '../../generated/types';

export const UPDATE_JOB_STATUS = gql`
  mutation UpdateJobStatus($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $status: JobStatus!) {
    updateJobStatus(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, status: $status) {
      uuid

      status

      updatedBy {
        uuid
        email
        nickname
        name
      }

      updatedAt
      appliedAt
      interviewedAt
      offerAt
      rejectedAt
      acceptedAt

      permissions
    }
  }
`;

export const useUpdateJobStatus = (
  options?: MutationHookOptions<Pick<Mutation, 'updateJobStatus'>, MutationUpdateJobStatusArgs>
) =>
  useMutation<Pick<Mutation, 'updateJobStatus'>, MutationUpdateJobStatusArgs>(UPDATE_JOB_STATUS, {
    ...options,
    update(cache, { data: { updateJob = {} } = {} }) {
      if (!updateJob.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: updateJob.uuid,
        }),
        fields: {
          status() {
            return updateJob.status;
          },
          updatedAt() {
            return updateJob.updatedAt;
          },
          appliedAt() {
            return updateJob.appliedAt;
          },
          interviewedAt() {
            return updateJob.interviewedAt;
          },
          offerAt() {
            return updateJob.offerAt;
          },
          acceptedAt() {
            return updateJob.acceptedAt;
          },
          rejectedAt() {
            return updateJob.rejectedAt;
          },
        },
      });
    },
  });
