import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveResumeArgs } from '../../generated/types';

export const ARCHIVE_EVENT = gql`
  mutation ArchiveResume($boardUuid: String!, $jobUuid: String, $resumeUuid: String!, $isDeleted: Boolean!) {
    archiveResume(boardUuid: $boardUuid, jobUuid: $jobUuid, resumeUuid: $resumeUuid, isDeleted: $isDeleted) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveResume = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveResume'>, MutationArchiveResumeArgs>
) =>
  useMutation<Pick<Mutation, 'archiveResume'>, MutationArchiveResumeArgs>(ARCHIVE_EVENT, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const boardUuid = options?.variables?.boardUuid;
      const archiveResume = data?.archiveResume;
      const archiveResumeUuid = archiveResume?.uuid;

      if (!boardUuid) return;
      if (!archiveResume) return;
      if (!archiveResumeUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Board',
          uuid: boardUuid,
        }),
        fields: {
          resumes(existingResumes = [], { readField }) {
            return [...existingResumes].filter((resumeRef) => {
              const resumeUuid = readField('uuid', resumeRef);
              return resumeUuid !== archiveResumeUuid;
            });
          },
        },
      });
    },
    ...options,
  });
