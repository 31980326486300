import styled from 'styled-components';

import { ProcessedResumeCreateProps, ProcessedResumeProps } from './ProcessedResume.types';
import { Container, ImageContainer, Left, LeftRight, LineWrapper, Right } from './ProcessedResume.styles';
import { Copy } from '../../atoms/Typography';
import { formatDate } from '../../../utils/date';
import { AddIcon, CopyIcon, DeleteIcon, DownloadIcon, EditIcon, TargetIcon, UploadIcon } from '../../atoms/Icons';
import { Colors } from '../../../styles/colors';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { IconButton } from '../../atoms/Icons/Icon';
import { FunctionalIFrameComponent } from '../../atoms/FunctionalIFrameComponent';
import { ResumePreview } from '../ResumePreview';
import { useState } from 'react';
import { UploadZone } from '../UploadZone';
import { PaperCollapse } from '../PaperCollapse';

export const ProcessedResume = ({
  resume,
  handleEditClick,
  handleDeleteClick,
  handleCopyClick,
  handleDownloadClick,
  handleTargetClick,
  isTargeted = false,
}: ProcessedResumeProps) => {
  const { state = {}, createdAt } = resume;
  const { resume: resumeData = {} } = state;
  const { basics = {} } = resumeData;
  const { jobTitle = '' } = basics;

  return (
    <Container>
      <LeftRight>
        <Left>
          <ImageContainer>
            <FunctionalIFrameComponent title="resume">
              <ResumePreview state={state} />
            </FunctionalIFrameComponent>
          </ImageContainer>
        </Left>
        <Right>
          <Copy marginBottom={8} color={Colors.ContrastDarkest} isBold={true}>
            {isTargeted ? <b>Tailored resume</b> : <b>Resume</b>}
          </Copy>
          <Copy marginBottom={0}>{jobTitle}</Copy>
          <Copy styleLevel={3} marginBottom={32} isItalic={true}>
            Updated{' '}
            {formatDate({
              date: createdAt,
              format: 'long',
            })}
          </Copy>
          {Boolean(handleEditClick) && (
            <>
              <LineWrapper onClick={handleEditClick}>
                <EditIcon size={2} color={Colors.Contrast} /> Edit
              </LineWrapper>
              <Spacer y={8} />
            </>
          )}
          {Boolean(handleDownloadClick) && (
            <>
              <LineWrapper onClick={handleDownloadClick}>
                <DownloadIcon size={2} color={Colors.Contrast} /> Download
              </LineWrapper>
              <Spacer y={8} />
            </>
          )}
          {Boolean(handleCopyClick) && (
            <>
              <LineWrapper onClick={handleCopyClick}>
                <CopyIcon size={2} color={Colors.Contrast} /> Duplicate
              </LineWrapper>
              <Spacer y={8} />
            </>
          )}
          {Boolean(handleDeleteClick) && (
            <>
              <LineWrapper onClick={handleDeleteClick}>
                <DeleteIcon size={2} color={Colors.Contrast} /> Delete
              </LineWrapper>
              <Spacer y={8} />
            </>
          )}
          {Boolean(handleTargetClick) && (
            <>
              <LineWrapper onClick={handleTargetClick}>
                <TargetIcon size={2} color={Colors.Contrast} /> Tailor
                <Copy styleLevel={3} isItalic={true}>
                  Tailor the resume to the Job Application. Premium Members have access to our AI assistant to help them
                  fill in the information and can download their resume in PDF format.
                </Copy>
              </LineWrapper>
              <Spacer y={8} />
            </>
          )}
        </Right>
      </LeftRight>
    </Container>
  );
};

export const ProcessedResumeCreate = ({
  handleResumeCreateFromScratch,
  handleResumeCreateFromResume,
}: ProcessedResumeCreateProps) => {
  const [loading, setLoading] = useState<'none' | 'requested' | 'inprogress'>('none');
  const icon = <AddIcon size={8} color={Colors.ContrastLightest} />;
  const newFiles = [];
  const setNewFiles = () => {};
  function handleFileDrop() {}

  return (
    <Container>
      <LeftRight>
        <Left>
          <ImageContainer>{icon}</ImageContainer>
        </Left>
        <Right>
          <Copy marginBottom={8} color={Colors.ContrastDarkest}>
            <b>Resume</b>
          </Copy>
          <Copy styleLevel={3} marginBottom={32} isItalic={true}>
            Generate a fresh resume by utilizing a design template and completing it with your information from the
            ground up.
          </Copy>
          <LineWrapper onClick={handleResumeCreateFromScratch}>
            <EditIcon size={2} color={Colors.Contrast} /> Create from Scratch
            <Copy styleLevel={3} isItalic={true}>
              Create a new resume from scratch. Premium Members have access to our AI assistant to help them fill in the
              information and can download their resume in PDF format.
            </Copy>
          </LineWrapper>
          <LineWrapper onClick={handleResumeCreateFromResume}>
            <UploadIcon size={2} color={Colors.Contrast} /> Start from an Resume
            <Copy styleLevel={3} isItalic={true}>
              Import your resume from a file and let our AI Assistant extract the information and draft a new resume for
              you. Only available for Premium Members.
            </Copy>
          </LineWrapper>
          {/* <PaperCollapse
            title={
              <>
                <UploadIcon size={2} color={Colors.Contrast} /> Start from an Resume
              </>
            }
          >
            <Copy styleLevel={3} isItalic={true}>
              Import your resume from a file and let our AI Assistant extract the information and draft a new resume for
              you. Only available for Premium Members.
            </Copy>
            <UploadZone
              copy={
                <>
                  <Copy marginBottom={10}>Drag &rsquo;n&rsquo; drop your Resume, or click to select a file</Copy>
                  <Copy marginBottom={30}>We accept MS Word, PDF & Richtext formats. </Copy>
                </>
              }
              files={newFiles}
              setFiles={setNewFiles}
              onFileDrop={handleFileDrop}
            ></UploadZone>
          </PaperCollapse> */}
        </Right>
      </LeftRight>
    </Container>
  );
};

export const ProcessedResumes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
`;
