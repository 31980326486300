import styled from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${Colors.ContrastLightest};
  padding: 8px;
  margin: 16px 12px 16px 0;
`;
