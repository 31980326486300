import MuiSwitch from '@mui/material/Switch';

import { SwitchProps } from './Switch.types';
import { Container } from './Switch.styles';

export const Switch = ({ label, isOn, isDisabled, size = 'medium', color = 'secondary', onChange }: SwitchProps) => {
  const inputProps = { inputProps: { 'aria-label': `Switch${label ? ` ${label}` : ''}` } };
  const checked = isOn ? true : false;
  const disabled = isDisabled ? true : false;

  return (
    <Container>
      <MuiSwitch {...inputProps} checked={checked} disabled={disabled} size={size} color={color} onChange={onChange} />
    </Container>
  );
};
