import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationInviteUserOnJobArgs } from '../../generated/types';

export const INVITE_USER_ON_JOB = gql`
  mutation InviteUserOnJob($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: EmailInputData!) {
    inviteUserOnJob(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data)
  }
`;

export const useInviteUserOnJob = (
  options?: MutationHookOptions<Pick<Mutation, 'inviteUserOnJob'>, MutationInviteUserOnJobArgs>
) => useMutation<Pick<Mutation, 'inviteUserOnJob'>, MutationInviteUserOnJobArgs>(INVITE_USER_ON_JOB, options);
