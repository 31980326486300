import styled from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  & .MuiSwitch-thumb {
    border: 1px solid ${Colors.GreyLightest};
    animation-duration: 400ms;
    width: 16;
    height: 16;
    margin: 2;
  }

  & .MuiSwitch-track {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 16;
      height: 16;
    }
    &:before {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="%23ffffff" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>');
      width: 16px;
      height: 16px;
      left: 13px;
    }
    &:after {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="%23ffffff" d="M19,13H5V11H19V13Z" /></svg>');
      width: 16px;
      height: 16px;
      right: 14px;
    }
  }
`;
