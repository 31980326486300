import { ProcessedFileProps } from './ProcessedFile.types';
import { Container, DocumentTitle, ImageContainer, Left, LeftRight, LineWrapper, Right } from './ProcessedFile.styles';
import { Copy } from '../../atoms/Typography';
import { formatDate } from '../../../utils/date';
import {
  DownloadIcon,
  EditIcon,
  FileGifIcon,
  FileIcon,
  FileJpgIcon,
  FilePdfIcon,
  FilePngIcon,
  FileWordIcon,
} from '../../atoms/Icons';
import { Mime } from '../../../generated/types';
import { Colors } from '../../../styles/colors';
import { Pill } from '../../atoms/Pill';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { SmallLoader } from '../../atoms/Loader';

export const ProcessedFile = ({ file, handleCreateClick }: React.PropsWithChildren<ProcessedFileProps>) => {
  const {
    filename,
    formattedMimetype,
    createdAt,
    temporaryUrl,
    temporaryPDFUrl,
    isConvertedToPDF,
    aiCoreSkills,
    aiSoftSkills,
    aiJobTitle,
    aiIsValidResume,
    aiIsValidCoverLetter,
    isProcessed,
    temporaryImageUrl,
  } = file;

  let icon = <></>;
  switch (formattedMimetype) {
    case Mime.Png:
      icon = <FilePngIcon size={8} color={isProcessed ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Gif:
      icon = <FileGifIcon size={8} color={isProcessed ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Jpeg:
      icon = <FileJpgIcon size={8} color={isProcessed ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Pdf:
      icon = <FilePdfIcon size={8} color={isProcessed ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Doc:
      icon = <FileWordIcon size={8} color={isProcessed ? Colors.Contrast : Colors.Primary} />;
      break;
    default:
      icon = <FileIcon size={8} color={isProcessed ? Colors.Contrast : Colors.Primary} />;
      break;
  }

  if (temporaryImageUrl) {
    icon = <img src={temporaryImageUrl} alt={filename} width={273} height={386} />;
  }

  return (
    <Container>
      {/* <pre> {JSON.stringify(file, null, 2)}</pre> */}
      <LeftRight>
        <Left>
          <ImageContainer>{icon}</ImageContainer>
        </Left>
        <Right>
          <Copy marginBottom={8} color={Colors.PrimaryDarkest}>
            {aiIsValidResume ? 'Resume' : aiIsValidCoverLetter ? 'Cover Letter' : 'Document'}
          </Copy>
          <DocumentTitle>{aiJobTitle ? aiJobTitle : filename}</DocumentTitle>
          {!aiJobTitle ? null : <Copy styleLevel={2}>{filename}</Copy>}
          <Copy styleLevel={3} marginBottom={16} isItalic={true}>
            Updated{' '}
            {formatDate({
              date: createdAt,
              format: 'long',
            })}
          </Copy>
          <LineWrapper
            onClick={() => {
              if (temporaryUrl) {
                window.open(temporaryUrl, '_blank');
              }
            }}
          >
            <DownloadIcon size={2} /> Download Original
          </LineWrapper>
          {isProcessed ? (
            <>
              {isConvertedToPDF && (
                <>
                  <Spacer y={8} />
                  <LineWrapper
                    onClick={() => {
                      if (temporaryPDFUrl) {
                        window.open(temporaryPDFUrl, '_blank');
                      }
                    }}
                  >
                    <DownloadIcon size={2} /> Download PDF
                  </LineWrapper>
                </>
              )}
              {aiIsValidResume && Boolean(handleCreateClick) && (
                <>
                  <Spacer y={8} />
                  <LineWrapper onClick={handleCreateClick}>
                    <EditIcon size={2} /> Create New Resume
                  </LineWrapper>
                </>
              )}
              {/* {aiIsValidResume && Boolean(handleTailorClick) && (
                <>
                  <Spacer y={8} />
                  <LineWrapper onClick={handleTailorClick}>
                    <EditIcon size={2} /> Tailor
                    <Copy styleLevel={3} isItalic={true}>
                      Tailor the uploaded resume to the Job Application. Premium Members have access to our AI assistant
                      to help them fill in the information and can download their resume in PDF format.
                    </Copy>
                  </LineWrapper>
                </>
              )} */}
              {aiCoreSkills && aiCoreSkills?.length > 0 && (
                <>
                  <Copy marginBottom={8} color={Colors.ContrastDarkest}>
                    Soft skills
                  </Copy>
                  <LineWrapper>
                    {aiSoftSkills?.map((skill) => {
                      return (
                        <Pill isSmall={true} key={skill} color={Colors.Contrast}>
                          {skill}
                        </Pill>
                      );
                    })}
                  </LineWrapper>
                </>
              )}
              {aiCoreSkills && aiCoreSkills?.length > 0 && (
                <>
                  <Copy marginBottom={8} color={Colors.PrimaryDarkest}>
                    Core skills
                  </Copy>
                  <LineWrapper>
                    {aiCoreSkills?.map((skill) => {
                      return (
                        <Pill isSmall={true} key={skill} color={Colors.Primary}>
                          {skill}
                        </Pill>
                      );
                    })}
                  </LineWrapper>
                </>
              )}
            </>
          ) : (
            <>
              <SmallLoader color={Colors.Primary} />
              <Copy styleLevel={3}>Processing...</Copy>
            </>
          )}
        </Right>
      </LeftRight>
    </Container>
  );
};
