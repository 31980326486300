import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveJobArgs } from '../../generated/types';
import { GET_JOBS } from '../queries/getJobs';

export const ARCHIVE_JOB = gql`
  mutation ArchiveJob($boardUuid: String!, $jobUuid: String!, $isDeleted: Boolean!) {
    archiveJob(boardUuid: $boardUuid, jobUuid: $jobUuid, isDeleted: $isDeleted) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveJob = (options?: MutationHookOptions<Pick<Mutation, 'archiveJob'>, MutationArchiveJobArgs>) =>
  useMutation<Pick<Mutation, 'archiveJob'>, MutationArchiveJobArgs>(ARCHIVE_JOB, {
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });
