import styled from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.div`
  border: 1px solid ${Colors.Contrast};
  border-radius: 4px;
  padding: 8px;
  display: flex;
  width: 100%;
  ${media.from_tablet`
    width: calc(50% - 8px);
  `}

  img {
    border-radius: 8px;
  }
`;

export const LeftRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
`;

export const LineWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.ContrastLightest};
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px dashed ${Colors.Contrast};
  height: 198px;
  width: 140px;

  ${media.from_tablet`
    height: 298px;
    width: 210px;
  `}

  ${media.from_laptop`
    height: 396px;
    width: 280px;
  `}
`;
